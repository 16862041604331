import * as React from "react";
import { Item } from "semantic-ui-react";
import { FeedCybozuTechBlog } from "../../graphql-types";

interface FeedElement {
  node: FeedCybozuTechBlog;
}
let baseDate = "";
const CybozuTechBlogFeedList: React.FC<Array<FeedElement>> = ({ feeds }) => {
  return (
    <Item.Group link divided relaxed="very">
      {feeds.map((element: FeedElement) => {
      const { id, title, link, isoDate, content, enclosure } = element.node;
      const date = new Date(isoDate);
      const disptitle = title.split('|')[0];
      const dispDate = date.toLocaleString().split(' ')[0];
      const dispPublishDate = dispDate !== baseDate;
      baseDate = dispPublishDate ? dispDate : baseDate;
      return (
        <>
        {dispPublishDate ? <h3 className="tech-blog-feeds">{dispDate}</h3> : null }
        <Item key={id} href={link} target="_blank" rel="noreferrer noopener" className="tech-blog-feeds-item">
          <Item.Image className="mobile hidden" size="small" src={enclosure.url}></Item.Image>
          <Item.Content>
            <Item.Header>{disptitle}</Item.Header>
            <Item.Description>{content.encoded.replace(/(<([^>]+)>)/ig, "").slice(0, 100)}...</Item.Description>
          </Item.Content>
        </Item>
        </>
      );
    })}
    </Item.Group>
  );
};

export default CybozuTechBlogFeedList;
