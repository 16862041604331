import * as React from "react";
import { Image, Card, Header } from "semantic-ui-react";

interface ExtraContentsInfo {
  banner: string;
  link: string;
  title: string;
}

const ExtraContents: ExtraContentsInfo[] = 
  [
      // CYBOZU SUMMER BLOG FES '24
      {
        title: "CYBOZU SUMMER BLOG FES '24",
        banner: "../images/blogs/CYBOZU_SUMMER_BLOG_FES_24_banner.png",
        link: "https://cybozu.github.io/summer-blog-fes-2024/"
      },
    ];

const CybozuPodcasts: ExtraContentsInfo[] =
[
        // サイボウズ フロントエンド通信
        {
          title: "サイボウズ フロントエンド通信",
          banner: "../images/blogs/cyfro_podcast.jpeg",
          link: "https://open.spotify.com/show/3vRymESsJYxiNkL1roDUeQ"
        },
        // Cybozu Design Podcast
        {
          title: "Cybozu Design Podcast",
          banner: "../images/blogs/Cybozu_Design_Podcast.jpeg",
          link: "https://open.spotify.com/show/1zU7QxfNVvnKhoDNZnzRTH"
        },
        // 流氷交差点
        {
          title: "流氷交差点",
          banner: "../images/blogs/DriftIceCrossing.jpg",
          link: "https://note.com/cybozutech/m/m9707f4c496e6"
        }
  
];
  

const ExtraContentsList: React.FC = () => {
  return ( 
    <>
    <Header as="h4" id="blogs-list-header">特別企画</Header>
    {/* CYBOZU SUMMER BLOG FES */}
      <Card.Group doubling centered>
      {ExtraContents.map((prop: ExtraContentsInfo) => {
        const { title, link, banner } = prop;
        return (
          <Card key={title} link href={link} target="_blank" rel="noreferrer noopener">
            <Card.Content>
              <Image floated="left" size="mini" src={banner} alt={`Icon: ${title}`} />
              <Card.Header className="blogs-card-header">{title}</Card.Header>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
    <Header as="h4" id="blogs-list-header">音声コンテンツ</Header>
    <Card.Group doubling centered>
      {CybozuPodcasts.map((prop: ExtraContentsInfo) => {
        const { title, link, banner } = prop;
        return (
          <Card key={title} link href={link} target="_blank" rel="noreferrer noopener">
            <Card.Content>
              <Image floated="left " size="mini" src={banner} alt={`Icon: ${title}`} />
              <Card.Header className="blogs-card-header">{title}</Card.Header>
            </Card.Content>
          </Card>
        );
      }
      )}
    </Card.Group>

          </>
         );
}

export default ExtraContentsList;
