import * as React from "react";
import { withLayout } from "../components/Layout";
import { Segment, Container, Header, Menu, Button, Grid, Item } from "semantic-ui-react";
import { FeedCybozuTechBlogConnection} from "../graphql-types";
import Title from "../components/Title";
import CybozuTechBlogFeedList from "../components/Blogs/CybozuTechBlogFeedList";
import CybozuTechBlogList from "../components/Blogs/CybozuTechBlogsList";
import ExtraContentsList from "../components/Blogs/ExtraContentsList";
import RSSSabo from "../components/Blogs/RSSSabo";
import ClientOnly from "../components/ClientOnly";
import { Link, graphql } from "gatsby";

const pageTitle = "Blogs";

interface CybozuTechBlogProps {
  data: {
    feeds: FeedCybozuTechBlogConnection;
  };
}

const CybozuTechBlog: React.FC<CybozuTechBlogProps> = (props) => {
  const feeds = props.data.feeds.edges;
  return (
    <Container fluid className="blog bg-sabo">
      <Container>
      <Title title={pageTitle} />
      {/* Contents */}
      <Segment vertical>
        <Grid doubling columns={2}>
          <Grid.Row>
          <Grid.Column width={11}>
            {/* サイボウズTech系ブログRSSの新着記事20件 */}
            <p>サイボウズのTech系ブログの記事（公開日降順）</p>
            <Container>
              <Segment vertical padded="very">
                <ClientOnly>
                  <CybozuTechBlogFeedList feeds={feeds}/>
                </ClientOnly>
              </Segment>
            </Container>
          </Grid.Column>
          <Grid.Column width={5}>
            <Container className="blogs-list">
            {/* rss feed link */}
            <RSSSabo />
            {/* ブログのリスト */}
            <Header as="h4" id="blogs-list-header">サイボウズのTech系Blog</Header>
            <CybozuTechBlogList />
            {/* CYBOZU SUMMER BLOG FES '24他 */}
            <ExtraContentsList />
            </Container>
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  </Container>
  );
};

export default withLayout(CybozuTechBlog, pageTitle);

export const pageQuery = graphql`
query RecentBlogQuery {
  # Get recent Cybozu Tech Blogs feeds
  feeds: allFeedCybozuTechBlog(
    sort: {fields: isoDate, order: DESC},
    limit: 20,
  ) {
    edges {
      node {
        id
        title
        link
        isoDate
        enclosure {
          url
        }
        content {
          encoded
        }
      }
    }
  }
}
`;
