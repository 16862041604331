import * as React from "react";
import { Image, Item, Card } from "semantic-ui-react";
import { BlogInfo } from "../../../tools/feed-util";

const CybozuTechBlogs: BlogInfo[] = 
  [
      // Cybozu Inside Out
      {
        title: "Cybozu Inside Out",
        thumbnailURL: "https://cdn.user.blog.st-hatena.com/default_entry_og_image/110495017/1514249404572921",
        description: "サイボウズ株式会社、サイボウズ・ラボ株式会社のエンジニアが提供する技術ブログです。製品やサービスの開発、運用で得た技術情報やエンジニアの活動、採用情報などをお届けします。",
        url: "https://blog.cybozu.io"
      },
      // Vietnam Tech Sharing
      {
        title: "Cybozu Vietnam Tech Sharing",
        thumbnailURL: "https://tech.cybozu.vn/img/seo-image.png",
        description: "This is a technical blog provided by engineers of Cybozu Vietnam Co., Ltd.",
        url: "https://tech.cybozu.vn/"
      },
      // Kintone Engineering Blog
      {
        title: "Kintone Engineering Blog",
        thumbnailURL: "https://cdn.user.blog.st-hatena.com/default_entry_og_image/110495017/1563765368291356",
        description: "Learn about Kintone's engineering efforts.  Kintone is provided by Cybozu Inc., a Tokyo-based public company founded in 1997.",
        url: "https://blog.kintone.io/"
      },
      // Cybozu Frontend Team
      {
        title: "サイボウズ フロントエンド",
        thumbnailURL: "https://storage.googleapis.com/zenn-user-upload/avatar/ec90598e61.jpeg",
        description: "サイボウズのフロントエンドに関わる人が発信するPublicationです",
        url: "https://zenn.dev/p/cybozu_frontend"
      },
      // Cybozu EPT Team
      {
        title: "サイボウズ 生産性向上チーム 💪",
        thumbnailURL: "https://storage.googleapis.com/zenn-user-upload/avatar/dfda292244.jpeg",
        description: "サイボウズ株式会社 開発本部 生産性向上チームです。サイボウズエンジニアの生産性を上げるために日々活動しています 💪",
        url: "https://zenn.dev/p/cybozu_ept"
      },
      // Cybozu Neco Team
      {
        title: "サイボウズ Necoチーム 😺",
        thumbnailURL: "https://storage.googleapis.com/zenn-user-upload/avatar/9e98c36648.jpeg",
        description: "サイボウズ株式会社 クラウド基盤本部 Cloud Platform Neco チームです。Kubernetes を主要な部品として、自社クラウドサービス cybozu.com を運用するためのデータセンター管理基盤の開発および運用をおこなっています。",
        url: "https://zenn.dev/p/cybozu_neco"
      },
      // Cybozu Product Design
      {
        title: "Cybozu Product Design Magazine",
        thumbnailURL: "https://assets.st-note.com/production/uploads/images/149991581/586a381258e83119a8f5ca669942756d.png?fit=bounds&format=jpeg&height=1024&quality=85&width=1024",
        description: "サイボウズのプロダクトデザインに関わるメンバーの記事をまとめたマガジンです",
        url: "https://note.com/cybozu_design/m/mc12622f890cf"
      },
      // Cybozu Security
      {
        title: "サイボウズのセキュリティ室",
        thumbnailURL: "https://assets.st-note.com/production/uploads/images/96430971/profile_c2b3dd26eca0b80c1fdc5653c8ee65c3.png?width=4000&height=4000&fit=bounds&format=jpg&quality=90",
        description: "サイボウズ株式会社のセキュリティ室です。セキュリティ文書や書籍の紹介・まとめ記事を公開することで、企業のセキュリティ対策が促進されることを期待します。",
        url: "https://note.com/security_cybozu/"
      },
      // Cybozu Data Team
      {
        title: "サイボウズ データチーム",
        thumbnailURL: "https://storage.googleapis.com/zenn-user-upload/avatar/eefd5b8e24.jpeg",
        description: "サイボウズ データチームの投稿一覧",
        url: "https://zenn.dev/p/cybozu_data"
      },
      // Cybozu People Experience Team
      {
        title: "サイボウズ People Experienceチームの活動",
        thumbnailURL: "https://assets.st-note.com/production/uploads/images/120086894/8f748e0e6adafc6dd2acaf3e32ad01dc.png?width=800",
        description: "サイボウズ 開発本部 People Experience チームのメンバーが投稿した記事です",
        url: "https://note.com/cybozu_dev_px/m/me5cfe2c532ef"
      },
      // Cybozu Developer Concourse
      {
        title: "Developer Concourse Unit の活動",
        thumbnailURL: "https://assets.st-note.com/production/uploads/images/133606911/d62bff56efa41ac6f98976a856202a5c.png?width=800",
        description: "サイボウズ株式会社 開発本部 People Experience チーム内で活動する，Developer Concourse Unit の活動記事をまとめるためのマガジンです",
        url: "https://note.com/cybozu_dev_px/m/m000a1ff77a6b"
      },
      // Cybozu Connect Support Team
      {
        title: "コネクト支援チームの活動",
        thumbnailURL: "https://assets.st-note.com/production/uploads/images/66960839/d2b0a594ac0d5855bcc6d5b7048f7eb5.png?width=800",
      description: "コネクト支援チームのメンバーが投稿した記事です。",
        url: "https://note.com/cybozutech/m/mc0c086e78c4f"
      },
      // 流氷自由帳
      {
        title: "流氷自由帳",
        thumbnailURL: "https://assets.st-note.com/production/uploads/images/50908121/ae8e943e85ee3c89a3f1b6d6931b074b.png?fit=bounds&format=jpeg&height=1024&quality=85&width=1024",
        description: "流氷交差点というサイボウズ株式会社 開発本部が配信する音声コンテンツの文字コンテンツです",
        url: "https://note.com/cybozutech/m/m9707f4c496e6"
      }
    ];
  


const CybozuTechBlogList: React.FC = () => {
  return ( 
    <>
      {/* Tech系ブログ一覧 */}
      <Card.Group doubling centered>
      {CybozuTechBlogs.map((prop: BlogInfo) => {
        const { title, url, thumbnailURL, description } = prop;
        return (
          <Card key={title} link href={url} target="_blank" rel="noreferrer noopener">
            <Card.Content>
              <Image floated="left" size="mini" src={thumbnailURL} alt={`Icon: ${title}`} />
              <Card.Header className="blogs-card-header">{title}</Card.Header>
              <Card.Description>{description}</Card.Description>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>

          </>
         );
}

export default CybozuTechBlogList;
